// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring-report";globalThis["SENTRY_RELEASE"] = {"id":"Zi2NsXe2auRIttGKJaVpi"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { denyUrls, ignoreErrors, compareSameEvent } from './sentry-config'
const SENTRY_DSN = process.env.SENTRY_DSN || ''

const throttleInterval = 1000 * 60 * 5
let lastEventInfo = null
let lastReportedTime = 0
Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.ENV || 'development',
  enabled: process.env.SENTRY_ENABLE === 'true',
  ignoreErrors: [...ignoreErrors],
  denyUrls: [...denyUrls],
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
    const now = Date.now()
    const isSameEvent = compareSameEvent(event, lastEventInfo)
    const canDrop = now - lastReportedTime < throttleInterval
    if (canDrop && isSameEvent) {
      return null
    }

    const error: any = hint.originalException
    if (error && error.message) {
      if ([...ignoreErrors].find((filter) => error.message.match(filter))) return null
    }

    lastEventInfo = event
    lastReportedTime = now

    return event
  },

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  profilesSampleRate: 1.0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true
    }),
    Sentry.rewriteFramesIntegration()
  ]
})
