/**
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */

// https://docs.sentry.io/platforms/javascript/configuration/filtering/
export const ignoreErrors = [
  // Random plugins/extensions
  // 'top.GLOBALS',
  // // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  // 'originalCreateNotification',
  // 'canvas.contentDocument',
  // 'MyApp_RemoveAllHighlights',
  // 'http://tt.epicplay.com',
  // "Can't find variable: ZiteReader",
  // 'jigsaw is not defined',
  // 'ComboSearch is not defined',
  // 'http://loading.retry.widdit.com/',
  // 'atomicFindClose',
  // // Facebook borked
  // 'fb_xd_fragment',
  // // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // // reduce this. (thanks @acdha)
  // // See http://stackoverflow.com/questions/4113268
  // 'bmi_SafeAddOnload',
  // 'EBCallBackMessageReceived',
  // // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  // 'conduitPage',

  // // Customs
  // 'Script error.',
  // 'Load failed',
  // /Network Error/i,
  // /Failed to fetch/i,
  // /NetworkError when attempting to fetch resource/i,
  // /.*@webkit-masked-url.*/,
  // /ResizeObserver loop completed with undelivered notifications/i,
  // /Object captured as exception with keys/i,
  // /Object captured as promise rejection with keys/i,
  // /no refresh token/i,
  // /\[Cloudflare Turnstile\]/i,
  // /Request aborted/i,
  // /globalThis is not defined/i,
  // /Text content does not match server-rendered HTML/i,
  // /There was an error while hydrating but React was able to recover/i,
  // /Hydration failed because the server rendered HTML didn't match/i,
  // /The server could not finish this Suspense boundary/i,
  // /Minified React error #(\d+); visit https:\/\/react\.dev\/errors\/\1/i
]

export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\//i,
  // Ignore Google
  /\/(gtm|ga|analytics|gtag)\.js/i,
  /.*google-analytics\.com.*/i,
  /.*googletagmanager\.com.*/i,
  /.*googleadservices\.com.*/i,
  /.*pagead2\.googlesyndication\.com.*/i,
  /.*maps\.googleapis\.com.*/,
  // Ignore Tiktok sdk
  /.*tiktok\.com.*/i,
  // Ignore Twitter sdk
  /.*twitter\.com.*/i,
  // Ignore Instagram sdk
  /.*instagram\.com.*/i,
  // Ignore Sentry sdk
  /.*sentry\.io.*/i,

  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Firefox extensions
  /^resource:\/\//i,
  // Edge extensions
  /^ms-resource:\/\//i,
  /^moz-extension:\/\//i,
  /^ms-browser-extension:\/\//i,
  // ARC Welder / ARC App
  /^chrome-extension:\/\/[a-p].{32}\//i,
  /^safari-extension:\/\//i,
  /^opera-extension:\/\//i

  // Other plugins
]

export function compareSameEvent(event1, event2) {
  if (!event1 || !event2) return false

  if (!event1.exception || !event2.exception) return false

  const exception1 = event1.exception.values[0]
  const exception2 = event2.exception.values[0]

  if (!exception1 || !exception2) return false
  if (exception1 && exception2) {
    const { type, value, stacktrace } = exception1
    const { type: type2, value: value2, stacktrace: stacktrace2 } = exception2
    if (stacktrace && stacktrace2) {
      const { frames } = stacktrace
      const { frames: frames2 } = stacktrace2
      if (frames && frames2) {
        const { filename, lineno, colno } = frames[frames.length - 1]
        const { filename: filename2, lineno: lineno2, colno: colno2 } = frames2[frames2.length - 1]
        if (
          filename == filename2 &&
          lineno == lineno2 &&
          colno == colno2 &&
          value == value2 &&
          type == type2
        ) {
          return true
        }
      }
    }
  }
  return false
}
